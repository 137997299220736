import * as React from 'react'
import Container from 'react-bootstrap/Container'
import SignUpToggler from '../common/sign-up-toggler'

const MainCTA = ({bgVariant, btnVariant}) => {
  const background = bgVariant || 'text-bg-primary';
  const button = btnVariant || 'light text-primary';
  return (
    <section id="mainCTA" className={`pt-3 py-sm-3 py-lg-4 py-xl-5 text-center ${background}`}>
      <Container className="d-flex flex-column align-items-center" id="sign-up">
        <h2 className="mb-2">Join the Journey</h2>
        <p className="lead mb-2">
          Be the first to hear about exclusive offers and events.
          <span className="d-sm-block">Sign up for updates here and never miss a beat.</span>
        </p>
        <SignUpToggler
          variant={button}
          btnText="Yes, I want in!"
          btnClass="btn-lg mb-2 mb-sm-0"
        />
      </Container>
    </section>
  )
}

export default MainCTA
