import * as React from 'react'
import Button from 'react-bootstrap/Button'
import ModalContext from '../context/modal-context'

const SignUpToggler = (props) => {
  // Button and modal defaults
  const variant = 'outline-dark'
  const btnClass = 'btn-lg'
  const btnText = 'Join the movement'
  const title = 'Become a Meati insider and never miss the next big thing'
  const text = 'Sign up to be the first to know when we launch in a new area or drop a hot new product.'

  return (

    <ModalContext.Consumer>
      {(context) => (
        <Button
          variant={props.variant || variant}
          className={[props.btnClass || btnClass, 'ad-el-tracked'].join(' ')}
          data-ad-element-type="cta button"
          data-ad-text={props.btnText || btnText}
          data-ad-button-id="version 1"
          data-ad-button-location="body"

          onClick={
            () => context.openModal(
              props.title || title,
              props.text || text,
            )
          }
        >
          { props.btnText || btnText }
        </Button>
      )}
    </ModalContext.Consumer>
  )
}

export default SignUpToggler
