import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import MainCTA from '../components/sections/main-cta'

const HolidayPage = () => {
  return (
    <Layout className="holiday">
      <Seo title="Holiday" />
      <header id="holidayHero" className="text-warning bg-primary py-2 d-flex align-items-center">
        <Container>
          <Row className="align-items-center align-items-xl-start">
            <Col md="7">
              <h1 className="fw-bold">Stay tuned. We're cookin' something up.</h1>
              <p className="subtitle mb-2 mb-md-0">Check back soon.</p>
            </Col>
            <Col md="5">
              <StaticImage
                className="recipe-icon-bg"
                src="../images/mo/meati-wink-yellow-flip.png"
                quality={95}
                formats={['AUTO', 'WEBP']}
                alt=""
                placeholder="none"
                layout="constrained"
                imgClassName="wave-top-image"
                style={{ transform: 'scaleX(-1)' }}
              />

              {/*
                  <div className="ratio ratio-1x1 video-container">
                    <iframe
                      src={`https://player.vimeo.com/video/760401880?h=ffe9f8f50b&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1"`}
                      allow="autoplay;"
                      title={`Meati™ winking Mo video`}
                      className="rounded-5"
                    />
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                 */}

            </Col>
          </Row>
        </Container>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default HolidayPage
